import { Grid } from "@material-ui/core";
import moment from "moment-timezone";
import PropType from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  BaseSection,
  DatePicker,
  fromId,
  Gender,
  TextField,
  ChooseOne,
  Optional,
  toId,
  IrdNumber,
  useGlobal
} from "up-form";


/** enum for applicant types (not in metadata) */
const applicantTypes = { Company: "Company", Student: "Student" };

const Personal = ({ section }) => {
  const { t } = useTranslation();
  const { providerSlug } = useGlobal();
  const enableIrdNumber = providerSlug !== "ichm";
  return (
    <BaseSection section={section} title={t("Personal.title")}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="preferredFirstName"
          label={t("Personal.preferredFirstName.label")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="previousNames"
          label={t("Personal.previousNames.label")}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DatePicker
          required
          fullWidth
          name="dateOfBirth"
          label={t("Personal.dateOfBirth.label")}
          disableFuture
          inputVariant="outlined"
          variant="inline"
          openTo="year"
          autoOk
          views={["year", "month", "date"]}
          format="DD/MM/yyyy"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Gender required name="gender" label={t("Personal.gender.label")} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Optional name="Personal.irdNumber">
          <IrdNumber
            fullWidth
            name="irdNumber"
            label={t("Personal.irdNumber.label")}
            inputProps={{ maxLength: 11 }}
            placeholder="000-000-000"
            hidden={!enableIrdNumber}
          />
        </Optional>
      </Grid>
      <Optional name="Personal.applicantType">
        <Grid item xs={12} sm={6}>
          <ChooseOne
            required
            name="applicantType"
            options={Object.values(applicantTypes)}
            label={t("Personal.applicantType.label")}
          />
        </Grid>
      </Optional>
    </BaseSection>
  );
};

export default Personal;

Personal.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};
const dateFormat = "YYYY-MM-DD";

export function mapToApplication({
  applicantType,
  gender,
  preferredFirstName,
  previousNames,
  dateOfBirth,
  irdNumber
}) {
  return {
    history: {
      previousKnownName: previousNames
    },
    student: {
      dateOfBirth: dateOfBirth && moment(dateOfBirth).format(dateFormat),
      genderId: toId(gender),
      preferredFirstName,
      irdNumber
    },
    opportunity: {
      isCompanyApplication: applicantType === applicantTypes.Company
    }
  };
}
export function mapFromApplication(
  {
    history: { previousKnownName } = {},
    opportunity: { isCompanyApplication } = {},
    student: { dateOfBirth, genderId, preferredFirstName, irdNumber } = {}
  },
  { metadata: { genders } }
) {
  return {
    dateOfBirth: dateOfBirth && moment(dateOfBirth),
    gender: fromId(genders, genderId),
    preferredFirstName,
    irdNumber,
    previousNames: previousKnownName,
    applicationType: isCompanyApplication
      ? applicantTypes.Company
      : applicantTypes.Student
  };
}